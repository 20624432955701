.root {
  padding-block: 10%;
}
.main {
  max-width: max(28.75%, 20.7rem);
  padding: 3rem 2.25rem;
  margin: auto;
}
.logo {
  margin-block-end: 1.6rem;
}
.text {
  margin-block-end: 1rem;
  text-align: center;
}
.title {
  margin-block-end: 0.83em;
}
.tip {
  margin-block-start: 1.6rem;
  color: #6e7191;
}

@media (max-width: 768px) {
  .root {
    padding-block: revert;
  }
}
