.root {
  padding: 1.5% 8.54%;
}
.root[data-step='2'] {
  padding: 10% 0;
}
.form {
  max-width: max(28.75%, 20.7rem);
  padding: 2rem;
  background: white;
  border-radius: 1rem;
}
.header {
  padding-inline: 5.4%;
  margin-block-end: 1.25em;
  text-align: center;
}
.logo {
  margin-block-end: 1rem;
}
.guide-slogan,
.terms {
  font-size: 0.7rem;
  text-align: center;
}
.guide-slogan {
  margin-block-start: 1.35em;
}
.terms {
  margin-block-start: 1.43em;
  color: var(--text-color-secondary);
}
.terms a {
  color: inherit;
}
.back-search {
  margin-block-end: 1.5rem;
}

.bn-result-header {
  margin-block-end: 1rem;
  text-align: center;
}
.bn-result-title {
  font-size: 1.2rem;
  line-height: 1.33;
}
.bn-result-subtitle {
  color: var(--text-color-secondary);
}
.bn-result {
  width: 100%;
  border-collapse: collapse;
}
.bn-result thead {
  border-block-end: 2px solid #d9dbe9;
}
.bn-result :is(th, td) {
  padding: 0.5em;
}
.bn-result :is(th, td):last-child {
  text-align: end;
}
.bn-result tbody tr {
  cursor: pointer;
}
.bn-result tbody tr:hover {
  font-weight: bold;
}
.bn-result tbody tr:hover td {
  background: #e9ffc0;
}
.bn-result tbody tr:hover td:first-child {
  border-start-start-radius: 1rem;
  border-end-start-radius: 1rem;
}
.bn-result tbody tr:hover td:last-child {
  border-start-end-radius: 1rem;
  border-end-end-radius: 1rem;
}
.bn-result th {
  text-align: start;
}
.bn-empty {
  padding-block: 8rem 5rem;
  text-align: center;
  color: var(--text-color-secondary);
  background: no-repeat center 35% / auto 3.2rem;
}
.country-code {
  align-self: center;
}

.back-wrap {
  margin-block-start: 1rem;
  text-align: center;
}
.back {
  text-decoration: underline;
}

.welcome {
  font-weight: 600;
}
.welcome > :not(:first-child) {
  margin-block-start: 1.25em;
}
.email-tip {
  margin-block-end: 1em;
}

@media (max-width: 768px) {
  .root,
  .root[data-step='2'] {
    padding: 0;
  }
  .form {
    max-width: revert;
    min-height: revert;
    margin: revert;
    border-radius: revert;
  }
}
