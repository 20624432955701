.root {
  display: flex;
  justify-content: space-between;
}
.item {
  display: flex;
  align-items: center;
  gap: 0.75em;
  cursor: pointer;
}
.control {
  display: none;
}
.control:checked ~ .fake {
  border-color: var(--primary-color);
  background: var(--secondary-dark-color);
}
.fake {
  composes: circle from '../../../utils.css';
  width: 2em;
  aspect-ratio: 1;
  border: 0.5em solid transparent;
  background: #d9dbe9;
}
