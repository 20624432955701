.root {
  composes: fullscreen from '../../utils.css';
  display: grid;
  backdrop-filter: blur(15px);
}
.backdrop {
  composes: fullscreen from '../../utils.css';
  position: absolute;
  background: var(--text-color-primary);
  opacity: 0.45;
}
.main {
  position: relative;
  width: max(44%, 31.75rem);
  max-height: 90%;
  padding: 2rem;
  margin: auto;
  overflow: auto;
  overscroll-behavior: contain;
  background: white;
  border-radius: 1.2rem;
}
.close {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
}

@media (max-width: 768px) {
  .main {
    width: auto;
    max-height: none;
    height: auto;
    padding: 1rem;
    margin: 1rem;
  }
}
