.root {
  height: 100vh;
  overflow: auto;
  background: no-repeat top / cover;
}

@media (max-width: 768px) {
  .root {
    height: revert;
    overflow: revert;
    background: none !important;
  }
}
