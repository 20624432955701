.root {
  composes: round from '../../../utils.css';
  position: relative;
  display: flex;
  height: 3.5em;
  border: 2px solid transparent;
  background: #eff0f6;
  border-radius: 0.4rem;
  color: var(--text-primary);
}
.root:focus-within {
  border-color: currentColor;
  background: none;
}
[data-error] .root {
  border-color: currentColor;
  color: var(--error-color);
  background: none;
}
.root .root {
  height: revert;
  background: revert;
}
.root .root:focus-within {
  border-color: transparent;
}
