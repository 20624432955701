.root {
  position: relative;
}
.control {
  display: block;
  width: 100%;
  padding-inline: 1.375em 3.5em;
  border: none;
  font-size: 0.8rem;
  color: inherit;
  background: none;
  appearance: none;
}
.control[data-emoji] {
  font-size: 1.6rem;
  padding-inline: 0.5em 1.5em;
}
.control:focus-visible {
  outline: none;
}
.icon {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  pointer-events: none;
}
