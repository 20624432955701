.root {
  composes: round from '../../utils.css';
  display: inline-flex;
  align-items: center;
  gap: 0.625em;
  padding: 1.125em 2.5em;
  border: 2px solid transparent;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1.5;
  color: var(--secondary-dark-color);
  background: var(--primary-color);
  cursor: pointer;
  user-select: none;
  transition: 0.15s;
}
.root[data-variant='text'],
.root[data-variant='link'] {
  padding: 0;
  border: none;
  background: none;
  border-radius: 0;
}
.root[data-variant='ghost'] {
  border-color: currentColor;
  background: none;
}
.root[data-variant='link'] {
  font: inherit;
  color: var(--text-color-primary);
  text-decoration: underline;
}
.root[data-block] {
  display: flex;
  justify-content: center;
  width: 100%;
}
.root[disabled] {
  opacity: calc(1 - 0.618);
  pointer-events: none;
}
.root[data-variant='default']:hover {
  color: var(--primary-color);
  background: var(--secondary-dark-color);
}
.root:not([data-variant='default']):hover {
  opacity: 0.9;
}
