@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);

/* Third-party overrides */

.toast-container {
  font-size: 0.8em;
}

/* Reset */

*,
::before,
::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
}

button,
input,
select {
  font-family: inherit;
}

/* Global */

html {
  --font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --primary-color: #9feb4d;
  --secondary-dark-color: #2b476f;
  --text-color-primary: #14142b;
  --text-color-secondary: #4e4b66;
  --text-color-tip: #6e7191;
  --error-color: #ff644f;

  font: 1.25em / 1.5 var(--font-family);
  color: var(--text-color-primary);
}
body {
  font-size: 0.8rem;
}
