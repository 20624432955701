.root {
  display: grid;
  min-height: 15rem;
}
.root[data-icon] {
  display: inline-grid;
  min-height: revert;
}
.root[data-icon]::after {
  width: auto;
  height: 1.5em;
  border-width: 2px;
  color: inherit;
}
.root::after {
  content: '';
  width: 1.8rem;
  aspect-ratio: 1;
  border: 4px solid;
  border-top-color: transparent;
  margin: auto;
  color: var(--primary-color);
  border-radius: 50%;
  animation: turn 1.5s linear infinite;
}

@keyframes turn {
  to {
    transform: rotate(1turn);
  }
}
