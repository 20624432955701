.root {
  margin-block-end: 1.5em;
}
.label {
  margin-block-end: 0.2em;
  font-weight: 600;
}
.optional {
  font-weight: normal;
}
.tip,
.error {
  margin-block-start: 0.65em;
  font-size: 0.7rem;
  line-height: 1.3;
  color: var(--text-color-tip);
}
.tip {
  color: var(--text-color-tip);
}
.error {
  color: var(--error-color);
}
.after:nth-child(n + 3) {
  margin-block-start: 1.1875em;
}
