.root[data-type='title'] {
  font-size: 1.2rem;
  line-height: 1.33;
}
.root[data-type='text-small'] {
  /* Fill this if global style removed one day. */
}
.root[data-type='text-x-small'] {
  font-size: 0.7rem;
  line-height: 1.57;
}
.root[data-bold] {
  font-weight: 600;
}
.root[data-type='title'][data-bold] {
  font-weight: bold;
}
