.control {
  flex: 1;
  display: block;
  width: 100%;
  padding-inline: 1.375em;
  border: none;
  font-size: 0.8rem;
  color: inherit;
  background: none;
}
.control:focus-visible {
  outline: none;
}
.suffix {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
}
