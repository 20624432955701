.round {
  border-radius: 99rem;
}
.circle {
  border-radius: 50%;
}

.fullscreen {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
