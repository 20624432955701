.root {
  display: flex;
  align-items: center;
  gap: 0.75em;
  cursor: pointer;
}
.root[data-size='s'] {
  font-size: 0.875em;
}
.control {
  display: none;
}
.control:checked ~ .fake {
  display: grid;
  place-items: center;
  background: var(--primary-color);
}
.control:checked ~ .fake::before {
  content: '✔️';
}
.fake {
  composes: circle from '../../../utils.css';
  width: 2em;
  aspect-ratio: 1;
  color: var(--secondary-dark-color);
  background: #d9dbe9;
}
